import React from "react";


export default function ProgressBar(props) {
  const { loading, progress, countProgress, isTsPrepared } = props;
  let howManyFiles = countProgress <= 2 ? 1 : countProgress;
  return (
    <React.Fragment>
      <div
        className={"bounty_Progress_Status"}
        style={!loading ? { display: "none" } : {}}
      >
        <div
          className={"bounty_myprogressBar"}
          style={{ width: `${Math.floor(progress / howManyFiles)}%` }}
        >
          {Math.floor(progress / howManyFiles)}%
        </div>
      </div>

      <div
        className={"bounty_spinner"}
        style={isTsPrepared ? { display: "block" } : { display: "none" }}
      >
        <div className={"bounty_double_bounce1"}></div>
        <div className={"bounty_double_bounce2"}></div>
      </div>
      <div
        className={"bounty_loading_title"}
        style={isTsPrepared ? { display: "block" } : { display: "none" }}
      >
        Preparing treatment plan
      </div>
    </React.Fragment>
  );
}
