import { getParams } from "./getParams";

export const howManyTsFiles = (manifest, isAttachments, hasSuperImpose) => {
  let count = 0;
  let params = getParams();
  let isAttachmentsCountable = isAttachments
    ? isAttachments
    : params["attachments"] === "true";
  if (manifest.lower) {
    Object.keys(manifest.lower).forEach((key) => {
      if (manifest.lower[key]) {
        if (key === "attachments" && isAttachmentsCountable) {
          count += manifest.lower[key].length;
        } else if (key !== "attachments") {
          count += manifest.lower[key].length;
        }
      }
    });
    count += hasSuperImpose ? 1 : 0; // add +1 for superImpose lower file
  }

  if (manifest.upper) {
    Object.keys(manifest.upper).forEach((key) => {
      if (manifest.upper[key]) {
        if (key === "attachments" && isAttachmentsCountable) {
          count += manifest.upper[key].length;
        } else if (key !== "attachments") {
          count += manifest.upper[key].length;
        }
      }
    });
    count += hasSuperImpose ? 1 : 0; // add +1 for superImpose upper file
  }
  return manifest.lower || manifest.upper ? count + 1 : count;
};
