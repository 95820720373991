import React from 'react';
import ReactDOM from 'react-dom';
import BountyTemplate from './TS/bounty-template/index'
import './App.css'

ReactDOM.render(
  <BountyTemplate/>,
  document.getElementById('root')
);

export { default as TS } from './TS/bounty-template/index'