import { meshesForStep } from '../TS-VIEWER/Scene';
export const generateStepsForMeshes = function(lowerSteps, upperSteps) {
    const howMany = Math.max(lowerSteps, upperSteps);
    //clear the meshesForStep array
    meshesForStep.filter(() => {
        return meshesForStep.splice(0);
    });
    //we populate the array of arrays for meshes here too
    for (let i = 0; i < howMany; i++) {
        meshesForStep.push([]);
    }
};
